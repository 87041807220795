import React, { useEffect, useState } from "react";
import useTitle from "../useTitle";
import {ProductPage, CurvedShape} from "../components";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import areas from '../core_items.json';
import $ from 'jquery';
import { Helmet } from "react-helmet";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const ProductServiceScreen = () => {
   
    useTitle("Prodotti e Servizi");

    const canonicalUrl = `${process.env.REACT_APP_MAIN_DOMAIN}${window.location.pathname}`;

    window.scrollTo(0, 0)
    const [title, setTitle] = useState("Open Innovation");
    const [current, setCurrent] = useState(0);
    const [captions, setCaptions] = useState([]);
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    const titleArea = [
        'Open Innovation',
        'Sviluppo Software',
        'Domotica',
        'Digital Signage',
        'Ricerca e Sviluppo'
    ]
    const simulateMouseClick = (element, area) => {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
            )
        );
        
    }
    const scrollDown = () => {
        $('html,body').animate({
            scrollTop: $(".containerDivDescriptionProduct").offset().top
        }, 'slow');
    }
    const changeSlide = (area) => {
        var element = "";
        if(area == "sviluppo"){
            element = document.querySelector('sliderProdotti, [data-index="1"]');
        }
        if(area == "domotica"){
            element = document.querySelector('sliderProdotti, [data-index="2"]');
        }
        if(area == "digital"){
            element = document.querySelector('sliderProdotti, [data-index="3"]');
        }
        if(area == "ricerca"){
            element = document.querySelector('sliderProdotti, [data-index="4"]');
        }
        if(element != ""){
            simulateMouseClick(element, area);
        }
    }
    
    useEffect(() => setCaptions(Object.entries(areas).map(area => area.description)), [areas]);
    useEffect(() => {
        document.title = title;
      }, [title]);
   
    const handleLoad = () => {
        setTimeout(
            () => {
                var open_button = document.querySelector('sliderProdotti, [data-index="0"]');
                open_button.title= "Open innovation";
                
                var sviluppo_button = document.querySelector('sliderProdotti, [data-index="1"]');
                sviluppo_button.title= "Sviluppo software";
                var domotica_button = document.querySelector('sliderProdotti, [data-index="2"]');
                domotica_button.title= "Domotica";
                var digital_button = document.querySelector('sliderProdotti, [data-index="3"]');
                digital_button.title= "Digital signage";
                var ricerca_button = document.querySelector('sliderProdotti, [data-index="4"]');
                ricerca_button.title= "Ricerca e sviluppo";
                const queryParams = new URLSearchParams(window.location.search);
                const area = queryParams.get('area');
                if(area){
                    changeSlide(area);
                };
            }
        ,100);
    }
   
    window.addEventListener('load', handleLoad());
    const goNext = (area) => {
        setCurrent(area.nextIndex); 
        //console.log(area.nextIndex)
        setTitle(titleArea[area.nextIndex])
    }
    return (
      <div id="sliderPS">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Scopri le nostre aree di competenza e i servizi che offriamo per ciascuna di esse!"
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <AutoplaySlider
          play={true}
          id="slider-prodotti"
          cancelOnInteraction={true}
          interval={0}
          onTransitionStart={(area) => goNext(area)}
          className="sliderProdotti"
          startup={true}
        >
          <div
            className="slide_prodotti"
            data-alt="Open innovation"
            data-src="/image/slider/prodotti_servizi/open_innovation.webp"
          >
            <div
              onClick={() => changeSlide("sviluppo")}
              id="sviluppo"
              style={{
                background: `url(/image/icon/SVILUPPO_SOFTWARE.webp)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="sfera1"
            ></div>
            <div
              onClick={() => changeSlide("domotica")}
              style={{
                background: `url(/image/icon/DOMOTICA.webp)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="sfera2"
            ></div>
            <div
              onClick={() => changeSlide("digital")}
              style={{
                background: `url(/image/icon/DIGITAL_SIGNAGE.webp)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="sfera3"
            ></div>
            <div
              onClick={() => changeSlide("ricerca")}
              style={{
                background: `url(/image/icon/RICERCA_E_SVILUPPO.webp)`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="sfera4"
            ></div>
          </div>
          <div
            className="slide_prodotti"
            data-alt="Sviluppo software"
            data-src="/image/slider/prodotti_servizi/sviluppo.webp"
          >
            <div onClick={() => scrollDown()} className="svc service1 ">
              <h4 className="serviceFont serviceSviluppo">SVILUPPO</h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service2 ">
              <h4 className="serviceFont serviceSviluppo">
                SECURITY ASSESSMENT
              </h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service3 ">
              <h4 className="serviceFont serviceSviluppo">CONSULENZA</h4>
            </div>
          </div>
          <div
            className="slide_prodotti"
            data-alt="Domotica"
            data-src="/image/slider/prodotti_servizi/domotica.webp"
          >
            <div onClick={() => scrollDown()} className="svc service4">
              <h4 className="serviceFont serviceDomotica">SVILUPPO IMPIANTI</h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service5">
              <h4 className="serviceFont serviceDomotica">ASSISTENZA</h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service6">
              <h4 className="serviceFont serviceDomotica">
                SECURITY ASSESSMENT
              </h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service7">
              <h4 className="serviceFont serviceDomotica">CONSULENZA</h4>
            </div>
          </div>
          <div
            className="slide_prodotti"
            data-alt="Digital signage"
            data-src="/image/slider/prodotti_servizi/digital.webp"
          >
            <div onClick={() => scrollDown()} className="svc service8">
              <h4 className="serviceFont serviceDigital">SVILUPPO CONTENUTI</h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service9">
              <h4 className="serviceFont serviceDigital">VENDITA</h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service10">
              <h4 className="serviceFont serviceDigital">CONSULENZA</h4>
            </div>
          </div>
          <div
            className="slide_prodotti"
            data-alt="Ricerca e sviluppo"
            data-src="/image/slider/prodotti_servizi/ricerca.webp"
          >
            <div onClick={() => scrollDown()} className="svc service11">
              <h4 className="serviceFont serviceRicerca">SVILUPPO</h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service12">
              <h4 className="serviceFont serviceRicerca">SVILUPPO PER TERZI</h4>
            </div>
            <div onClick={() => scrollDown()} className="svc service13">
              <h4 className="serviceFont serviceRicerca">CONSULENZA</h4>
            </div>
          </div>
        </AutoplaySlider>
        <CurvedShape cname="svg-shapes" />

        <ProductPage selected={current} captions={captions} area={"slider"} />
        <CurvedShape cname="svg-shapes-rotated" />
      </div>
    );
};

export default ProductServiceScreen;