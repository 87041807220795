import React, { useState, useCallback } from "react";
import "../styles/Modal.css";
import TurnstileWidget from "./Turnstile";

const ContactForm = React.memo(() => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!recaptchaValue) {
        alert("Per favore completa la verifica del reCaptcha.");
        return;
      }

      if (name && email && message) {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact",
            name,
            email,
            object,
            message,
            "cf-turnstile-response": recaptchaValue, // Token Turnstile
          }),
        })
          .then(() => {
            setShowModal(true);
          })
          .catch((error) => alert(error));
      }
    },
    [name, email, object, message, recaptchaValue]
  );

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const closeModal = () => {
    setShowModal(false); //Close the modal when you click "Close"
  };

  const handleTurnstileVerify = (token) => {
    setRecaptchaValue(token);
  };

  return (
    <form
      name="contact"
      data-netlify="true"
      onSubmit={handleSubmit}
      className="contactForm"
    >
      <div className="inlineFormRow">
        <div className="inlineFormField">
          <p>Nome</p>
          <input
            className="inputForm"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="inlineFormField">
          <p>Email</p>
          <input
            className="inputForm"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="formField">
        <p>Soggetto</p>
        <input
          className="inputForm"
          type="text"
          name="object"
          value={object}
          onChange={(e) => setObject(e.target.value)}
        />
      </div>
      <div className="formField">
        <p>Messaggio</p>
        <textarea
          className="textAreaForm"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>

      {/* Turnstile Widget */}
      <div className="formField">
        <TurnstileWidget onVerify={handleTurnstileVerify} />
      </div>

      <div className="buttonRow">
        <button className="formButton" type="submit" disabled={!recaptchaValue}>
          INVIA
        </button>
      </div>
      {/* Modal for success message */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Grazie per averci contattato</h2>
            <p>Ti ricontatteremo il prima possibile.</p>
            <button onClick={closeModal}>Chiudi</button>
          </div>
        </div>
      )}
    </form>
  );
});

export default ContactForm;
