import React, { useState } from "react";
import useTitle from "../useTitle";
import { Slider, CurvedShape, HomeSector } from "../components";
import coreItems from "../core_items.json";
import ContactForm from "../components/Form";
import MyMap from "../components/Map";
import { cookieHelper } from "../helpers/cookieHelper";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Helmet } from "react-helmet";
import CookieConsentModal from "../components/CookieConsentModal";
import "../styles/CookieModal.css";

const HomeScreen = () => {
  useTitle("Home");
  const canonicalUrl = `${process.env.REACT_APP_MAIN_DOMAIN}${window.location.pathname}`;
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  React.useEffect(() => {
    function handleResize() {
      var hWhy = $(".titleWhyChoose").height();
      $(".titleWhyChoose").css({ top: "calc(50% - " + hWhy / 2 + "px)" });
      var hProduct = $("#btnOurProductHome").height();
      $(".btnOurProductHome").css({
        top: "calc(50% - " + hProduct / 2 + "px)",
      });
    }
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);
  });

  React.useEffect(() => {
    if (!cookieHelper.getCookiePreferences()) {
      setShowCookieBanner(true);
    }
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Lifetronic S.R.L. è un azienda che si occupa di sviluppo software, domotica, digital signage e ricerca e sviluppo."
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <Slider />
      <CurvedShape cname="svg-shapes" />

      <CookieConsentModal
        open={showCookieBanner}
        hideCancelBtn={!cookieHelper.getCookiePreferences()}
        currentPreferences={cookieHelper.getCookiePreferences()}
        setOpen={(open) => {
          if (cookieHelper.getCookiePreferences()) {
            setShowCookieBanner(open);
          }
        }}
        onSubmit={(preferences) => {
          cookieHelper.setCookiePreferences(preferences);
          setShowCookieBanner(false);
          reloadPage();
        }}
        className="cookieConsentModal" // Verifica che questa classe sia correttamente applicata
      />

      <div className="sectorsDiv">
        {Object.entries(coreItems).map(([key, value]) => {
          return <HomeSector key={key} {...value} />;
        })}
      </div>
      <div
        className="imageWhyChoose"
        style={{
          backgroundImage: `url("/image/homepage_2.webp")`,
          marginBottom: "50px",
        }}
      >
        <div className="layerImageWhyChoose"></div>
        <h1 className="titleWhyChoose">PERCHÈ SCEGLIERE LIFETRONIC?</h1>
      </div>
      <div className="qualityRow">
        <div className="qualityDiv">
          <h3 className="sectorTitle qualityTitle">Qualità</h3>
          <div className="qualityIcon">
            <svg
              width="117"
              height="112"
              viewBox="0 0 117 112"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_i_0_86)">
                <path
                  d="M56.5974 1.85655C57.1961 0.0139248 59.8029 0.0139221 60.4016 1.85654L72.7561 39.8799C73.0239 40.7039 73.7918 41.2618 74.6583 41.2618H114.638C116.576 41.2618 117.381 43.7411 115.814 44.8799L83.4693 68.3796C82.7684 68.8889 82.4751 69.7916 82.7428 70.6156L95.0973 108.639C95.696 110.482 93.5871 112.014 92.0197 110.875L59.6751 87.3753C58.9741 86.866 58.0249 86.866 57.3239 87.3753L24.9794 110.875C23.4119 112.014 21.303 110.482 21.9017 108.639L34.2562 70.6156C34.524 69.7916 34.2306 68.8889 33.5297 68.3796L1.18511 44.8799C-0.382318 43.7411 0.423231 41.2618 2.36068 41.2618H42.3408C43.2072 41.2618 43.9751 40.7039 44.2429 39.8799L56.5974 1.85655Z"
                  fill="#B59852"
                />
              </g>
              <defs>
                <filter
                  id="filter0_i_0_86"
                  x="0.356873"
                  y="0.474548"
                  width="116.285"
                  height="116.792"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="6" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_0_86"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <p
            style={{ color: "#101010", textAlign: "center", marginTop: "30px" }}
          >
            Proponiamo servizi e progetti di automazione altamente innovativi,
            grazie a tecnologie fortemente affidabili.
          </p>
        </div>
        <div className="qualityDiv">
          <h3 className="sectorTitle qualityTitle">Efficienza</h3>
          <div className="qualityIcon">
            <svg
              width="120"
              height="117"
              viewBox="0 0 120 117"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_i_0_88)">
                <path
                  d="M6.52899 102.059L44.3635 67.9548C44.3635 67.9548 44.3635 69.287 45.6957 70.6192C47.0279 71.9514 48.6265 72.4843 48.6265 72.4843L13.9893 108.987L6.52899 102.059Z"
                  fill="#95C8E8"
                />
                <circle
                  r="15"
                  transform="matrix(-1 0 0 1 14.9995 101.002)"
                  fill="#95C8E8"
                />
                <path
                  d="M81.9995 41.4997L82.0219 41.4796C82.1144 41.3814 82.3994 40.8996 80.9995 39.4998C79.5631 38.0633 79.0933 38.4009 79.0127 38.4841L78.9995 38.4997C78.9995 38.4997 79.0036 38.4935 79.0127 38.4841L107.637 4.79802L115.097 11.7255L82.0219 41.4796C82.0088 41.4935 81.9995 41.4997 81.9995 41.4997Z"
                  fill="#95C8E8"
                />
                <circle
                  r="12.7891"
                  transform="matrix(1 0 0 -1 106.837 12.7913)"
                  fill="#95C8E8"
                />
                <path
                  d="M98.6381 94.9274L78.9993 72.8048C78.9993 72.8048 78.9601 73.4277 77.9995 73.9998C77.0389 74.5718 76.6161 74.4843 76.6161 74.4843L93.354 99.2177L98.6381 94.9274Z"
                  fill="#95C8E8"
                />
                <circle
                  cx="93.1754"
                  cy="93.8499"
                  r="10.3881"
                  transform="rotate(3.80436 93.1754 93.8499)"
                  fill="#95C8E8"
                />
                <path
                  d="M47.3968 41.3573L47.3689 41.3314C47.3225 41.2678 47.2783 41.0691 47.7698 40.5428C48.4081 39.8593 48.9524 39.9616 48.9524 39.9616L34.3946 21.8781L30.2227 25.3901L47.3689 41.3314C47.3827 41.3504 47.3968 41.3573 47.3968 41.3573Z"
                  fill="#95C8E8"
                />
                <circle
                  cx="34.6141"
                  cy="26.1755"
                  r="6.85067"
                  transform="rotate(-177.213 34.6141 26.1755)"
                  fill="#95C8E8"
                />
                <circle cx="63.9995" cy="55.9998" r="19" fill="#B59852" />
              </g>
              <defs>
                <filter
                  id="filter0_i_0_88"
                  x="-0.000488281"
                  y="0.00213623"
                  width="119.627"
                  height="122"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="6" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_0_88"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <p
            style={{ color: "#101010", textAlign: "center", marginTop: "30px" }}
          >
            Dedichiamo risorse e attenzione per servizi di manutenzione
            post-vendita in maniera puntuale e tempestiva.
          </p>
        </div>
        <div className="qualityDiv">
          <h3 className="sectorTitle qualityTitle">Adattabilità</h3>
          <div className="qualityIcon">
            <svg
              width="169"
              height="106"
              viewBox="0 0 169 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_i_0_104)">
                <path
                  d="M101.035 50.7777C102.359 51.5494 102.359 53.4619 101.035 54.2335L29.0069 96.2191C27.6736 96.9963 25.9998 96.0345 25.9998 94.4912L25.9998 10.5201C25.9998 8.97676 27.6736 8.01498 29.0069 8.79217L101.035 50.7777Z"
                  fill="#B59852"
                />
                <path
                  d="M74.1791 77.8201C73.2331 78.3904 72.9253 79.622 73.5315 80.5454C79.3922 89.4719 87.8703 96.3925 97.8264 100.342C108.403 104.537 120.064 105.148 131.021 102.08C141.978 99.0114 151.627 92.4339 158.487 83.3559C165.347 74.2778 169.04 63.2002 168.999 51.8218C168.959 40.4433 165.188 29.3921 158.264 20.3628C151.34 11.3334 141.645 4.82441 130.667 1.83381C119.688 -1.1568 108.032 -0.463901 97.4851 3.80626C87.5572 7.82582 79.1282 14.8062 73.3308 23.774C72.7311 24.7016 73.0476 25.931 73.9976 26.4946L114.124 50.2993C115.424 51.071 115.431 52.9514 114.136 53.7322L74.1791 77.8201Z"
                  fill="#95C8E8"
                />
              </g>
              <defs>
                <filter
                  id="filter0_i_0_104"
                  x="25.9998"
                  y="0.00561523"
                  width="143"
                  height="110"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="6" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_0_104"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <p
            style={{ color: "#101010", textAlign: "center", marginTop: "30px" }}
          >
            Lifetronic si mette a disposizione del cliente sviluppando prodotti
            e servizi in base alle sue esigenze.
          </p>
        </div>
      </div>
      <div
        id="imgOurProduct"
        className="imageWhyChoose"
        style={{
          backgroundImage: `url("/image/imageOurProduct.webp")`,
          marginTop: "50px",
        }}
      >
        <div className="layerImageWhyChoose"></div>
        <Link to="/portfolio?goup=y" className="btnOurProductHome">
          LAVORI REALIZZATI DA NOI
        </Link>
      </div>
      <CurvedShape cname="svg-shapes" />
      <h2 className="whereTitle">Dove Siamo</h2>
      <MyMap />
      <h2 className="whereTitle">Inviaci una mail</h2>
      <ContactForm />
      <CurvedShape cname="svg-shapes-rotated" />
    </>
  );
};

export default HomeScreen;
