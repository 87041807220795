
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({text}) => <div className="MarkerMap" style={{
                                        background: `url("/image/LogoLife.png")` ,
                                        backgroundPosition: "center",
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                      }}></div>;

const MyMap = ({props}) => {
    const [center, setCenter] = useState({
      lat: 43.679702566573454,
      lng: 10.432529703170689,
    });
    const [zoom, setZoom] = useState(15);
    return (
      <div style={{ height: "60vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyASU7B7k2P1Vec8dH2PjP98Mi4eFUArbm4" }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <AnyReactComponent
            lat={43.6797}
            lng={10.4325}
            text="Lifetronic s.r.l."
          />
        </GoogleMapReact>
      </div>
    );
}

export default MyMap;
