import React, { useState } from "react";
import useTitle from "../useTitle";
import { CurvedShape } from "../components";
import $ from "jquery";
import { Helmet } from "react-helmet";

const PrivacyPolicyScreen = React.memo(() => {
  const [loaded, setLoaded] = useState(false);
  var text = "sviluppo_software";
  var elementTitle = "";

  const changeTitleBySlide = (title) => {
    document.title = title.textContent;
  };
  const handleLoad = () => {
    setTimeout(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const zone = queryParams.get("area");
      const goUp = queryParams.get("goup");
      if (loaded == false) {
        if (zone) {
          $("html,body").animate(
            {
              scrollTop: $("#portfolioButtonDiv").offset().top,
            },
            "slow"
          );
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        } else if (goUp) {
          window.scrollTo(0, 0);
        }
        setTimeout(() => {
          elementTitle = document.querySelector(
            "#description_portfolio_portfolio_" + text + " > h3"
          );
          changeTitleBySlide(elementTitle);
        }, 50);
        setLoaded(true);
      }
    }, 100);
  };

  window.addEventListener("load", handleLoad());
  useTitle("Privacy Policy");
  const canonicalUrl = `${process.env.REACT_APP_MAIN_DOMAIN}${window.location.pathname}`;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        style={{
          display: `none`,
        }}
      ></div>
      <div
        className="aboutHeaderImage"
        style={{
          backgroundImage: `url("/image/homepage_2.webp")`,
        }}
      >
        <div className="titleWhyChoose">
          <h1>PRIVACY POLICY</h1>
        </div>
      </div>
      <CurvedShape cname="svg-shapes" />
      <div id="portfolioButtonDiv" className="portfolioButtonDiv"></div>
      <CurvedShape cname="svg-shapes-rotated" />
    </>
  );
});

export default PrivacyPolicyScreen;
