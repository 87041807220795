import { Navbar, Footer, Sidebar } from "./components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screen/HomeScreen";
import ProductServiceScreen from "./screen/ProductServiceScreen";
import AboutScreen from "./screen/AboutScreen";
import PortfolioScreen from "./screen/PortfolioScreen";
import PrivacyPolicyScreen from "./screen/PrivacyPolicyScreen";
import CookiePolicyScreen from "./screen/CookiePolicyScreen";

function App() {
  return (
    <Router className="App">
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/prodotti_servizi" element={<ProductServiceScreen />} />
        <Route path="/chi_siamo" element={<AboutScreen />} />
        <Route path="/portfolio" element={<PortfolioScreen />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
        <Route path="/cookie-policy" element={<CookiePolicyScreen />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
