import Cookies from "js-cookie";

const setCookiePreferences = (preferences) => {
  Cookies.set("cookiePreferences", JSON.stringify(preferences), {
    expires: 365,
    domain: process.env.REACT_APP_MAIN_DOMAIN,
    path: "/",
    secure: true,
    sameSite: "Lax",
  });
};

const getCookiePreferences = () => {
  const c = Cookies.get("cookiePreferences");
  let result;
  try {
    result = JSON.parse(c);
  } catch (error) {}
  return result;
};

export const cookieHelper = {
  setCookiePreferences,
  getCookiePreferences,
};
