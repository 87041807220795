import React from "react";
import { SocialComponent } from "../utils/links";
import logo from "../assets/image/logo_bianco.png";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div
        className="footer-container"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* Sezione CONTATTI */}
        <div className="footer-contacts">
          <div
            className="logo-img"
            style={{
              background: `url(${logo})`,
              backgroundPosition: "left",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: "80px",
            }}
          />
          <h5>CONTATTI</h5>
          <br></br>
          <p>Lifetronic S.r.l</p>
          <p>Via Agostino Bassi, 3</p>
          <p>CAP 56121</p>
          <p>Pisa</p>
          <br></br>
          <p>Tel. +39.050.877757</p>
          <p>Fax +39.050.877756</p>
          <p>info@lifetronic.it</p>
        </div>

        {/* Sezione INFORMATIVE */}
        <div className="footer-informative">
          <div
            className="logo-img"
            style={{
              backgroundPosition: "left",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              height: "80px",
            }}
          />
          <h5>INFORMATIVE</h5>
          <br></br>
          {/*<p>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Informativa sulla privacy
            </a>
          </p>*/}
          <p>
            <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
              Informativa sui cookie
            </a>
          </p>
        </div>
      </div>

      {/* Sezione Copyright */}
      <div
        className="footer-bottom"
        style={{ textAlign: "left", marginTop: "30px" }}
      >
        <p>
          ©Copyright {new Date().getFullYear()} - www.lifetronic.it - Partita
          IVA 01886150505 - Codice SDI: SZLUBAI
        </p>
      </div>

      {/* Sezione Social */}
      <div className="footer-social">
        <SocialComponent classSocial="socialTop" />
      </div>
    </footer>
  );
};

export default Footer;
