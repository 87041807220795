import React, { useState, useEffect} from "react";
import useTitle from "../useTitle";
import {CurvedShape, PortfolioArea} from "../components";
import $ from 'jquery';
import { Helmet } from "react-helmet";

const PortfolioScreen = React.memo(() => {
    const values = [
        { id: 1, text: "sviluppo_software", value:"Sviluppo Software" },
        { id: 2, text: "domotica", value:"Domotica" },
        { id: 3, text: "digital_signage", value:"Digital Signage" },
        { id: 4, text: "ricerca_e_sviluppo", value:"Ricerca e sviluppo" }
    ];
    const [loaded, setLoaded] = useState(false);
    const [activeId, setActiveId] = useState(1);
    const [currentSector, setCurrentSector] = useState("sviluppo_software");
    var text = 'sviluppo_software';
    var elementTitle = "";

    const changeArea = (area, id) => {
        $('#'+currentSector).hide();
        $('#'+area).show();
        
        setCurrentSector(area);
        setActiveId(id);
        setTimeout(()=>{
            var elementTitle = document.querySelector("#description_portfolio_portfolio_"+area+" > h3");
            changeTitleBySlide(elementTitle);
        },100)
    };
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    const simulateMouseClick = (element) => {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
            )
        );
    }

    const changeSlider = (area, slide) => {
        var element = "";
        var elementSlide = "";
        if(area == "sviluppo"){
            element = document.querySelector('#button_sviluppo_software');
            text = values[0].text;
            if(slide){
                elementSlide = document.querySelector('#sviluppo_software > div > div.awssld.awssld--organic-arrows.sliderHome > nav > [data-index="'+slide+'"]');
            }
        }
        if(area == "domotica"){
            element = document.querySelector('#button_domotica');
            text = values[1].text;
            if(slide){
                elementSlide = document.querySelector('#domotica > div > div.awssld.awssld--organic-arrows.sliderHome > nav > [data-index="'+slide+'"]');
            }
        }
        if(area == "digital"){
            element = document.querySelector('#button_digital_signage');
            text = values[2].text;
            if(slide){
                elementSlide = document.querySelector('#digital_signage > div > div.awssld.awssld--organic-arrows.sliderHome > nav > [data-index="'+slide+'"]');
            }
        }
        if(area == "ricerca"){
            element = document.querySelector('#button_ricerca_e_sviluppo');
            text = values[3].text;
            if(slide){
                elementSlide = document.querySelector('#ricerca_e_sviluppo > div > div.awssld.awssld--organic-arrows.sliderHome > nav > [data-index="'+slide+'"]');
            }
        }
       
        if(element != ""){
            simulateMouseClick(element);
            
        }else{
            text =values[0].text;
        }
        if(elementSlide != ""){
            setTimeout(()=>{
                simulateMouseClick(elementSlide);
            },100)
            
        }
       
    }
    const changeTitleBySlide = (title) => {
        document.title =title.textContent;

    }
    const handleLoad = () => {
        setTimeout(
            () => {
                const queryParams = new URLSearchParams(window.location.search);
                const zone = queryParams.get('area');
                const goUp = queryParams.get('goup');
                const slide = queryParams.get('slide');
                if(loaded == false){
                    if(zone){
                        $('html,body').animate({
                            scrollTop: $("#portfolioButtonDiv").offset().top
                        }, 'slow');
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        changeSlider(zone, slide);
                        
                    }else if(goUp){
                        window.scrollTo(0, 0);
                    };
                    setTimeout(()=>{
                        //console.log("#description_portfolio_portfolio_"+text+" > h3")
                        elementTitle = document.querySelector("#description_portfolio_portfolio_"+text+" > h3");
                        changeTitleBySlide(elementTitle);
                    },50)
                    setLoaded(true);

                }   
        }
        ,100);
    }
   
    window.addEventListener('load', handleLoad());
    useTitle("Portfolio");
    const canonicalUrl = `${process.env.REACT_APP_MAIN_DOMAIN}${window.location.pathname}`;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="I nostri progetti passati, presenti e futuri con informazioni specifiche su di essi per aiutarti a capire meglio di cosa ci occupiamo!"
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <div
          style={{
            display: `none`,
          }}
        >
          <a href="/portfolio/?area=sviluppo&goup=y&slide=0"></a>
          <a href="/portfolio/?area=sviluppo&goup=y&slide=1"></a>
          <a href="/portfolio/?area=sviluppo&goup=y&slide=2"></a>
          <a href="/portfolio/?area=sviluppo&goup=y&slide=3"></a>
          <a href="/portfolio/?area=sviluppo&goup=y&slide=4"></a>
          <a href="/portfolio/?area=sviluppo&goup=y&slide=5"></a>

          <a href="/portfolio/?area=domotica&goup=y&slide=0"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=1"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=2"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=3"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=4"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=5"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=6"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=7"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=8"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=9"></a>
          <a href="/portfolio/?area=domotica&goup=y&slide=10"></a>

          <a href="/portfolio/?area=digital&goup=y&slide=0"></a>
          <a href="/portfolio/?area=digital&goup=y&slide=1"></a>
          <a href="/portfolio/?area=digital&goup=y&slide=2"></a>
          <a href="/portfolio/?area=digital&goup=y&slide=3"></a>
          <a href="/portfolio/?area=digital&goup=y&slide=4"></a>

          <a href="/portfolio/?area=ricerca&goup=y&slide=0"></a>
          <a href="/portfolio/?area=ricerca&goup=y&slide=1"></a>
          <a href="/portfolio/?area=ricerca&goup=y&slide=2"></a>
          <a href="/portfolio/?area=ricerca&goup=y&slide=3"></a>
          <a href="/portfolio/?area=ricerca&goup=y&slide=4"></a>
          <a href="/portfolio/?area=ricerca&goup=y&slide=5"></a>
        </div>
        <div
          className="aboutHeaderImage"
          style={{
            backgroundImage: `url("/image/homepage_2.webp")`,
          }}
        >
          <div className="titleWhyChoose">
            <h1>PORTFOLIO PROGETTI</h1>
          </div>
        </div>
        <CurvedShape cname="svg-shapes" />
        <div id="portfolioButtonDiv" className="portfolioButtonDiv">
          {values.map((val) => (
            <button
              id={"button_" + val.text}
              onClick={() => changeArea(val.text, val.id)}
              className={
                activeId === val.id
                  ? "ActivePortfolio_" +
                    val.text +
                    " portBtn portfolioButton_" +
                    val.text
                  : " portBtn portfolioButton_" + val.text
              }
            >
              {val.value}
            </button>
          ))}
        </div>

        <PortfolioArea area="sviluppo_software" />
        <PortfolioArea area="domotica" />
        <PortfolioArea area="digital_signage" />
        <PortfolioArea area="ricerca_e_sviluppo" />
        <CurvedShape cname="svg-shapes-rotated" />
      </>
    );
});

export default PortfolioScreen;
