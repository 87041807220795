import { useState } from "react";
import "../styles/CookieModal.css"; // Importa il CSS per lo stile

export default function CookieConsentModal({ open, setOpen, onSubmit }) {
  const defaultPreferences = { necessary: true, analytics: false };
  const [preferences, setPreferences] = useState(defaultPreferences);

  // Funzione per chiudere la modale
  const closeModal = () => setOpen(false);

  // Funzione per inviare le preferenze
  const handleSubmit = () => {
    onSubmit(preferences);
    closeModal();
  };

  return (
    open && (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Preferenze sui Cookie</h2>
          <p>
            Utilizziamo i cookie per migliorare la tua esperienza online. Puoi
            scegliere se abilitare i cookie analitici.
          </p>

          {/* Checkbox per i cookie necessari (sempre attivo e disabilitato) */}
          <div className="checkbox-section">
            <div className="flex items-center">
              <input
                id="necessaryCookies"
                type="checkbox"
                disabled
                checked={preferences?.necessary}
                onChange={() => {}}
                className="form-checkbox"
              />
              <label htmlFor="necessaryCookies" className="checkbox-label">
                Cookie necessari
              </label>
            </div>
            <p className="text-sm mt-1 text-gray-500">
              Questi cookie sono essenziali per il funzionamento del sito.
            </p>
          </div>

          {/* Checkbox per i cookie analitici */}
          <div className="checkbox-section">
            <div className="flex items-center">
              <input
                id="analyticsCookies"
                type="checkbox"
                checked={preferences?.analytics}
                onChange={(e) =>
                  setPreferences({
                    ...preferences,
                    analytics: e.target.checked,
                  })
                }
                className="form-checkbox"
              />
              <label htmlFor="analyticsCookies" className="checkbox-label">
                Cookie di analisi
              </label>
            </div>
            <p className="text-sm mt-1 text-gray-500">
              Questi cookie ci aiutano a raccogliere informazioni per migliorare
              il nostro sito.
            </p>
          </div>

          {/* Footer con bottoni */}
          <div className="button-container">
            <button onClick={closeModal} className="button-cancel">
              Annulla
            </button>
            <button
              onClick={handleSubmit}
              disabled={!preferences?.necessary}
              className={`button-primary ${
                !preferences?.necessary ? "button-disabled" : ""
              }`}
            >
              Salva preferenze
            </button>
          </div>
        </div>
      </div>
    )
  );
}
