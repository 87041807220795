import React, { useEffect, useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";

const TurnstileWidget = ({ onVerify }) => {
  const turnstile = useTurnstile();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (isVerified) {
      // When it has been verified, perform an action, such as disabling the widget or doing other actions
    }
  }, [isVerified]);

  const handleVerify = (token) => {
    onVerify(token);
    setIsVerified(true);
  };

  return (
    <Turnstile sitekey="0x4AAAAAAA28-SQc38zISSrA" onVerify={handleVerify} />
  );
};

export default TurnstileWidget;
