import React, { useState } from "react";
import useTitle from "../useTitle";
import { CurvedShape } from "../components";
import $ from "jquery";
import { Helmet } from "react-helmet";

const CookiePolicyScreen = React.memo(() => {
  const [loaded, setLoaded] = useState(false);
  var text = "sviluppo_software";
  var elementTitle = "";

  const changeTitleBySlide = (title) => {
    document.title = title.textContent;
  };
  const handleLoad = () => {
    setTimeout(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const zone = queryParams.get("area");
      const goUp = queryParams.get("goup");
      if (loaded == false) {
        if (zone) {
          $("html,body").animate(
            {
              scrollTop: $("#portfolioButtonDiv").offset().top,
            },
            "slow"
          );
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        } else if (goUp) {
          window.scrollTo(0, 0);
        }
        setTimeout(() => {
          elementTitle = document.querySelector(
            "#description_portfolio_portfolio_" + text + " > h3"
          );
          changeTitleBySlide(elementTitle);
        }, 50);
        setLoaded(true);
      }
    }, 100);
  };

  window.addEventListener("load", handleLoad());
  useTitle("Cookie Policy");
  const canonicalUrl = `${process.env.REACT_APP_MAIN_DOMAIN}${window.location.pathname}`;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        style={{
          display: `none`,
        }}
      ></div>
      <div
        className="aboutHeaderImage"
        style={{
          backgroundImage: `url("/image/homepage_2.webp")`,
        }}
      >
        <div className="titleWhyChoose">
          <h1>COOKIE POLICY</h1>
        </div>

      </div>
        <CurvedShape cname="svg-shapes"/>
        <div id="portfolioButtonDiv" className="portfolioButtonDiv">
            <div className="page-content">
                <p>Il presente documento fornisce informazioni sull'uso dei cookie sul sito
                    web <strong>lifetronic.it</strong> (di seguito, il "Sito").</p>

                <h2>Cosa sono i cookie?</h2>
                <p>I cookie sono piccoli file di testo che i siti web visitati dall'utente inviano al suo terminale
                    (computer, tablet, smartphone), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti
                    alla visita successiva.</p>

                <h2>Tipologie di cookie utilizzati</h2>
                <p>Il Sito utilizza le seguenti categorie di cookie:</p>

                <h3>1. Cookie tecnici</h3>
                <p>Questi cookie sono essenziali per il corretto funzionamento del Sito e permettono all'utente di
                    navigare e utilizzare i suoi servizi. Non necessitano del consenso dell'utente.</p>
                <p>Esempi di cookie tecnici includono:</p>
                <ul>
                    <li>Cookie per la gestione della sessione.</li>
                    <li>Cookie per il salvataggio delle preferenze (es. lingua, impostazioni).</li>
                </ul>

                <h3>2. Cookie di Google Analytics</h3>
                <p>Il Sito utilizza Google Analytics, un servizio di analisi web fornito da Google LLC ("Google"), per
                    raccogliere informazioni in forma anonima e aggregata sul numero di visitatori e su come questi
                    interagiscono con il Sito.</p>
                <p>Questi cookie sono impostati in modalità anonimizzata (es. con mascheramento dell'indirizzo IP) e non
                    consentono di identificare personalmente gli utenti.</p>
                <ul>
                    <li>Per maggiori informazioni su come Google utilizza i dati raccolti tramite Google Analytics, puoi
                        consultare la <a href="https://policies.google.com/technologies/partner-sites" target="_blank"
                                         rel="noopener noreferrer">pagina dedicata di Google</a>.
                    </li>
                    <li>È possibile disabilitare Google Analytics installando il componente aggiuntivo per il browser
                        disponibile <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"
                                       rel="noopener noreferrer">qui</a>.
                    </li>
                </ul>

                <h2>Gestione dei cookie</h2>
                <p>L'utente può gestire le preferenze relative ai cookie direttamente tramite le impostazioni del
                    proprio
                    browser. È possibile, ad esempio, disattivare l'uso di cookie o eliminare quelli già installati.</p>
                <p>Di seguito alcuni link utili per la gestione dei cookie sui principali browser:</p>
                <ul>
                    <li><a href="https://support.google.com/chrome/answer/95647" target="_blank"
                           rel="noopener noreferrer">Google Chrome</a></li>
                    <li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank"
                           rel="noopener noreferrer">Mozilla Firefox</a></li>
                    <li><a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank"
                           rel="noopener noreferrer">Safari</a></li>
                    <li><a
                        href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                        target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
                </ul>

                <h2>Modifiche alla Cookie Policy</h2>
                <p>Il Titolare del trattamento si riserva il diritto di modificare la presente Cookie Policy in
                    qualsiasi
                    momento. Le modifiche saranno pubblicate su questa pagina con indicazione della data di ultimo
                    aggiornamento.</p>

                <p><strong>Ultimo aggiornamento:</strong> 17 Gennaio 2025</p>
            </div>
        </div>
        <CurvedShape cname="svg-shapes-rotated"/>
    </>
  );
});

export default CookiePolicyScreen;
